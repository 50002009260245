import { ComponentProps } from 'react';
import { IntlShape } from 'react-intl';
import { Product, RealTimeInfo } from 'types/Product';
import { ATPMessage } from 'ui';
import { checkDateBetweenDates, formatDate } from 'utils/dateUtil';
import { uppercaseFirstLetter } from 'utils/textUtil';
import { ATP_MESSAGES, DELIVERY_MOMENTS } from '../constants/atp';
import { PRODUCT_STOCK_MESSAGE, ProductCarrier } from '../constants/product';
import { areEqualDates, calculateDaysBetweenDates } from './dateUtil';

export function getAtpDeliveryMoment(currentDate: string, deliveryMoment: string) {
  if (!(currentDate && deliveryMoment)) {
    return null;
  }

  const daysBetween = calculateDaysBetweenDates(deliveryMoment, currentDate);

  switch (daysBetween) {
    case 1:
      return 'tomorrow';
    case 2:
      return 'overmorrow';
    default:
      return formatDate(new Date(deliveryMoment), { day: '2-digit', month: '2-digit' });
  }
}

export const getAtpMessage = (
  locale: string,
  formatMessage: IntlShape['formatMessage'],
  stockMessage?: string,
  realtimeInfo?: RealTimeInfo,
  overruleStockMessage?: string,
  preOrderExpectedDeliveryDate?: string,
  isTradeplaceProduct?: boolean,
  firstAppearanceEndDate?: Date,
  shouldFormatStockMessage?: boolean,
  carrier?: ProductCarrier,
) => {
  const currentDate = new Date().toString();

  if (overruleStockMessage) {
    return shouldFormatStockMessage ? formatMessage({ id: overruleStockMessage }) : overruleStockMessage;
  }

  if (preOrderExpectedDeliveryDate) {
    return shouldFormatStockMessage
      ? formatMessage({ id: preOrderExpectedDeliveryDate })
      : preOrderExpectedDeliveryDate;
  }

  if (isTradeplaceProduct) {
    return formatMessage({
      id: ATP_MESSAGES.TRADEPLACE_MESSAGE,
    });
  }

  if (realtimeInfo?.atp?.stockMessage) {
    if (realtimeInfo?.atp?.stockMessage === PRODUCT_STOCK_MESSAGE.STOCK) {
      const deliveryPromisesArray = realtimeInfo?.deliveryPromises || [];

      if (!deliveryPromisesArray?.length) {
        return formatMessage({
          id: realtimeInfo?.atp?.stockMessage,
        });
      }

      const deliveryMomentPromise = deliveryPromisesArray.find(
        (promise) => promise.calculationDate && areEqualDates(currentDate, promise.calculationDate),
      );

      const deliveryMoment =
        deliveryMomentPromise?.deliveryPromise &&
        getAtpDeliveryMoment(currentDate, new Date(deliveryMomentPromise.deliveryPromise).toString());

      if (deliveryMoment) {
        switch (deliveryMoment) {
          case DELIVERY_MOMENTS.TOMORROW:
            return formatMessage({
              id: carrier ? `${ATP_MESSAGES.STOCK_TOMORROW}_${carrier?.toLowerCase()}` : ATP_MESSAGES.STOCK_TOMORROW,
            });
          case DELIVERY_MOMENTS.OVERMORROW:
            return formatMessage({
              id: carrier
                ? `${ATP_MESSAGES.STOCK_AFTER_TOMORROW}_${carrier?.toLowerCase()}`
                : ATP_MESSAGES.STOCK_AFTER_TOMORROW,
            });
          default:
            return formatMessage(
              {
                id: carrier
                  ? `${ATP_MESSAGES.STOCK_DELIVERY_DATE}_${carrier?.toLowerCase()}`
                  : ATP_MESSAGES.STOCK_DELIVERY_DATE,
              },
              { date: deliveryMoment },
            );
        }
      }
    }

    if (firstAppearanceEndDate) {
      return formatMessage(
        {
          id: realtimeInfo?.atp?.stockMessage,
        },
        {
          date: formatDate(firstAppearanceEndDate, { day: '2-digit', month: '2-digit' }),
        },
      );
    }

    return formatMessage({
      id: realtimeInfo?.atp?.stockMessage,
    });
  }

  if (stockMessage) {
    return shouldFormatStockMessage ? formatMessage({ id: stockMessage }) : stockMessage;
  }

  return formatMessage({ id: ATP_MESSAGES.TEMP_NO_STOCK });
};

type MapAtpMessageParams = {
  formatMessage: IntlShape['formatMessage'];
  locale: string;
  product: Product;
  realtimeInfo?: RealTimeInfo;
};

export const mapAtpMessage = ({ formatMessage, locale, product, realtimeInfo }: MapAtpMessageParams) => {
  const atpLabel = getAtpMessage(
    locale,
    formatMessage,
    product?.atp?.stockMessage,
    realtimeInfo,
    product?.atp?.overruleStockMessage,
    product?.preOrderExpectedDeliveryDate,
    product?.isTradeplaceProduct,
    product?.firstAppearanceEndDate,
    true,
    product?.carrier,
  );

  const addToCartMessage = (realtimeInfo?.atp?.addToCartMessage || product.atp?.addToCartMessage) ?? '';

  const capitalizedAtpLabel = uppercaseFirstLetter(atpLabel) ?? '';
  const canBeSold = product.atp?.addToCartMessage === ATP_MESSAGES.BUTTON_STOCK;
  const variant: ComponentProps<typeof ATPMessage>['variant'] = canBeSold ? 'success' : 'error';

  const isPreOrderActive =
    product?.preOrderStartDate && product?.preOrderEndDate
      ? checkDateBetweenDates(new Date().toString(), product.preOrderStartDate, product.preOrderEndDate)
      : false;

  const preOrderLabel =
    isPreOrderActive && product?.preOrderStartDate
      ? formatMessage(
          { id: 'pdp_delivery_info_pre_order_date' },
          {
            start: formatDate(product.preOrderStartDate, { day: '2-digit', month: '2-digit' }),
          },
        )
      : '';

  const deliveryPromise = realtimeInfo?.deliveryPromises?.[0]?.deliveryPromise;
  const stockMessage = (realtimeInfo?.atp?.stockMessage || product?.atp?.stockMessage) ?? '';
  const atpDescription = (realtimeInfo?.atp?.description || product?.atp?.description) ?? '';

  return {
    addToCartMessage,
    atpDescription,
    canBeSold,
    deliveryPromise,
    label: capitalizedAtpLabel,
    preOrderLabel,
    stockMessage,
    variant,
  };
};
